import MenuCard from './MenuCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import MenuListUtil,{IMenuListDataObj} from './menuListUtil';
import language from './menuLanguage'
export default defineComponent ({
    name: 'MenuList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IMenuListDataObj=reactive<IMenuListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                // isShowQueryParam:false,
                // canPage:false,
                // isShowFixCol:false,
                // dragRow:true,
                queryParam: {},
                modelComp: MenuCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/menu/pageData')
            },
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MenuListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'show'==params.comboId){
                    return [{value:0,label:'是'},{value:1,label:'否'}]
                }
                if(params && 'prop'==params.comboId){
                    return [{value:0,label:'站内打开'},{value:1,label:'站外打开'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{

            return options;
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo
        }
    }
});