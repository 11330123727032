export default {
    zh:{
        menu:{
            queryParam:{
                caption:'caption',
                caption_placeholder:'请输入caption',
                path:'path',
                path_placeholder:'请输入path',
                page:'page',
                page_placeholder:'请输入page',
                show:'show',
                show_placeholder:'请选择show',
                prop:'prop',
                prop_placeholder:'请选择prop',
            },
            columns:{
                F_PARENT_ID:'上级菜单',
                F_CAPTION:'caption',
                F_PATH:'path',
                F_PAGE:'page',
                F_ICON:'icon',
                F_SHOW:'show',
                F_PROP:'prop',
            },
            form:{
                parentId:'上级菜单',
                parentId_placeholder:'请输入上级菜单',
                index:'index',
                index_placeholder:'请输入index',
                caption:'caption',
                caption_placeholder:'请输入caption',
                caption_rule: '请输入caption',
                path:'path',
                path_placeholder:'请输入path',
                path_rule: '请输入path',
                page:'page',
                page_placeholder:'请输入page',
                icon:'icon',
                icon_placeholder:'请输入icon',
                pic:'pic',
                pic_placeholder:'请输入pic',
                permission:'permission',
                permission_placeholder:'请输入permission',
                show:'show',
                show_placeholder:'请选择show',
                prop:'prop',
                prop_placeholder:'请输入prop',
            },
        }
    },
    en:{
        menu:{
            queryParam:{
                caption:'caption',
                caption_placeholder:'请输入caption',
                path:'path',
                path_placeholder:'请输入path',
                page:'page',
                page_placeholder:'请输入page',
                show:'show',
                show_placeholder:'请选择show',
                prop:'prop',
                prop_placeholder:'请选择prop',
            },
            columns:{
                F_PARENT_ID:'上级菜单',
                F_CAPTION:'caption',
                F_PATH:'path',
                F_PAGE:'page',
                F_ICON:'icon',
                F_SHOW:'show',
                F_PROP:'prop',
            },
            form:{
                parentId:'上级菜单',
                parentId_placeholder:'请输入上级菜单',
                index:'index',
                index_placeholder:'请输入index',
                caption:'caption',
                caption_placeholder:'请输入caption',
                caption_rule: '请输入caption',
                path:'path',
                path_placeholder:'请输入path',
                path_rule: '请输入path',
                page:'page',
                page_placeholder:'请输入page',
                icon:'icon',
                icon_placeholder:'请输入icon',
                pic:'pic',
                pic_placeholder:'请输入pic',
                permission:'permission',
                permission_placeholder:'请输入permission',
                show:'show',
                show_placeholder:'请选择show',
                prop:'prop',
                prop_placeholder:'请输入prop',
            },
        }
    }
}